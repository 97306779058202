<template>
  <data-form2
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import CountriesDataService from "../../services/countries.service";

export default {
  name: "countries-form",
  props: ["data"],
  components: {
    DataForm2,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [{ code: { label: "Oznaka" } }, { name: { label: "Naziv" } }],

          [
            { code_alpha: { label: "Šifra (alpha)" } },
            { code_num: { label: "Šifra (num)", type: "number" } },
          ],

          // [{ divider: { type: "divider" } }],
        ],
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      CountriesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      CountriesDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Država je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>